import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    Textarea,
    theme,
    useBreakpointValue
} from "@chakra-ui/react";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {EmailIcon, PhoneIcon} from "@chakra-ui/icons";
import {FaUserAlt} from "react-icons/fa";
import {useFormData} from "../../Context/FormDataContext";
import {useLocation} from "react-router-dom";

export const SignUpForm = ({description, maxW}) => {
    const {pathname} = useLocation();
    const formRef = useRef(null);
    const [animate, setAnimate] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const animationApplied = useRef(false);
    const {updateFormData} = useFormData(); // Use the context
    const useBreakpoint = useBreakpointValue({base: "md", md: "lg"})
    console.log(pathname, "path")

    const handleSubmit = (event) => {
        event.preventDefault();

        const myForm = event.target;
        const formData = new FormData(myForm);

        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => {
                setFormSuccess(true)
                console.log("Form successfully submitted")
            })
            .catch((error) => alert(error));
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        updateFormData(name, value); // Update the shared form data
    };

    const checkVisibility = () => {
        if (formRef.current) {
            const rect = formRef.current.getBoundingClientRect();
            return rect.top >= 0 && rect.bottom <= window.innerHeight;
        }
        return false;
    };

    useEffect(() => {
        // Immediate check for visibility and apply animation if visible
        if (checkVisibility() && !animationApplied.current) {
            setAnimate(true);
            animationApplied.current = true; // Mark that animation has been applied
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !animationApplied.current) {
                        setAnimate(true);
                        animationApplied.current = true; // Mark that animation has been applied
                        observer.unobserve(formRef.current);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        // Start observing
        if (formRef.current) {
            observer.observe(formRef.current);
        }

        // Cleanup
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <Box ref={formRef} className={animate ? 'animate__animated animate__slideInRight' : ''}
             height={formSuccess ? 'full' : 'initial'}  pb={{base: 10, md: 0}}>
            {formSuccess ?
                <Alert
                    status='success'
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='full'
                    bg={'#368c88'}
                    color={'#fff'}
                    py={{base: 20, md: 0}}

                >
                    <AlertIcon boxSize='40px' mr={0} color={'#fff'}/>
                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                        Submitted!
                    </AlertTitle>
                    <AlertDescription maxWidth='sm'>
                        Thanks for submitting your information. Our team will get back to you soon.
                    </AlertDescription>
                </Alert>
                : (<Container mr={{base: 0, md: 20}} mb={{base: '3rem', md: 0}} p={'2rem'} background={'#f4f4f4'}>
                        <Stack spacing={{base: 0, md: 8}}>
                            <Stack spacing="6">
                                {/*<Logo />*/}
                                <Stack spacing="3">
                                    <Heading size={useBreakpoint}>Get <Text
                                        color={'#64bcb8'}
                                        as="span">FREE</Text> estimate!</Heading>
                                    <Text
                                        color="muted">{description || 'Provide some information and get a quote today'}</Text>
                                </Stack>
                            </Stack>

                            <Stack spacing="6">
                                <form name="contact" method="POST" action={"/?index"} data-netlify="true"
                                      onSubmit={handleSubmit}>
                                    <input type="hidden" name="form-name" value="contact"/>
                                    <Stack spacing="4">
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="name">Name</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement pointerEvents='none'>
                                                    <FaUserAlt style={{
                                                        color: theme.colors.gray['300'],
                                                        position: 'relative',
                                                        top: '5px'
                                                    }}/>
                                                </InputLeftElement>
                                                <Input id="name" type="text" name="name" style={{paddingLeft: '2rem'}}
                                                       onChange={handleInputChange}/>
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="email">Email</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement pointerEvents='none'>
                                                    <EmailIcon color='gray.300' pos={'relative'} top={'5px'}/>
                                                </InputLeftElement>
                                                <Input id="email" type="email" name="email"
                                                       style={{paddingLeft: '2rem'}}
                                                       onChange={handleInputChange}/>
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="phone">Phone number</FormLabel>
                                            <InputGroup alignItems={'center'}>
                                                <InputLeftElement pointerEvents='none'>
                                                    <PhoneIcon color='gray.300' pos={'relative'} top={'5px'}/>
                                                </InputLeftElement>
                                                <Input id="phone" type="tel" name="phone" style={{paddingLeft: '2rem'}}
                                                       onChange={handleInputChange}/>
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel htmlFor="comment">Comment</FormLabel>
                                            <Textarea id="comment" name="comment" background={'#fff'}/>
                                        </FormControl>
                                        <HStack spacing="1">
                                            <Button type={'submit'} size="lg" bg={'#64bcb8'} color={'#fff'}
                                                    _hover={{bg: '#329490', color: '#fff'}}
                                                    fontWeight="bold" mt="2">
                                                Submit
                                            </Button>
                                        </HStack>
                                    </Stack>
                                </form>
                            </Stack>
                        </Stack>
                    </Container>
                )
            }
        </Box>
    );
}


const DesktopForm = () => {
    return (
        <SignUpForm/>
    )
}
const MobileForm = () => {
    return (
        <SignUpForm/>
    )
}

export const LeadForm = () => {
    const isMobile = useBreakpointValue({base: true, md: false})
    return isMobile ? <MobileForm/> : <DesktopForm/>
}