import React from "react";

const AboutBelowContent = ({ data }) => {
  const { title, text: description } = data;

  return (
    <div className="about_below_content">
      <i className="ion ion-ios-checkmark-outline" aria-hidden="true"></i>
      <div className="about_below_content_text">
        <h3>{title}</h3>
        {description}
      </div>
    </div>
  );
};

export default AboutBelowContent;
