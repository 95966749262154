import React from "react";
import AboutBelowContent from "../../Components/ExperienceBlocks/AboutBelowContent";
import { SiteData } from "../../Constants/siteData";
import { Box, Center, Grid, GridItem, Heading, Stack } from "@chakra-ui/react";
import { LeadForm } from "../../Components/SignUpForm/SignUpForm";

const ExperiencesWide = () => {
  const headerData = {
    // img: 'images/about/shadow_icon1.png',
    heading: SiteData.homepage.servicesTitle,
    subheading: SiteData.homepage.servicesHeader,
  };

  return (
    <Box
      pt={{ base: 10, md: 20 }}
      pb={10}
      px={{ base: 5, md: 0 }}
      mb={{ base: 0, md: 20 }}
      mt={{ base: 0, md: 10 }}
    >
      <div className="experience">
        <div className="container">
          <Grid
            order={"-1"}
            templateRows={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(5, 1fr)" }}
            gap={{ base: 0, md: 8 }}
          >
            <GridItem rowSpan={1} colSpan={3}>
              <Center>
                <div className="experience_content">
                  <div className="section_header">
                    {/*<div className="shadow_icon"><MdOutlineConstruction fontSize={'2rem'}/></div>*/}
                    <Heading
                      as={"span"}
                      color={"#FFA903"}
                      textTransform={"uppercase"}
                      fontSize={"md"}
                    >
                      {headerData.subheading}
                    </Heading>
                    <Heading as={"h2"} py={4}>
                      {headerData.heading}
                    </Heading>
                    {SiteData.homepage.servicesSubtitle}
                    <div className="about_below">
                      <Stack>
                        <Box>
                          {SiteData.homepage.services.map((data) => (
                            <AboutBelowContent key={data.id} data={data} />
                          ))}
                        </Box>
                      </Stack>
                    </div>
                  </div>
                </div>
              </Center>
            </GridItem>
            <GridItem
              rowSpan={1}
              colSpan={2}
              gridRow={{ base: 1, md: "unset", lg: "unset" }}
            >
              <LeadForm />
            </GridItem>
          </Grid>
        </div>
      </div>
    </Box>
  );
};

export default ExperiencesWide;
