export const posts = [
    {
        id: '0',
        title: 'Navigating Los Angeles’ Rental Market Trends',
        excerpt: 'Stay ahead in the competitive Los Angeles rental market. Read insights and trends that can help maximize your property investments.',
        slug: 'navigating-los-angeles-rental-market-trends',
        tags: ['Rental Market', 'Los Angeles', 'Investment Strategies'],
        metaDescription: 'Get expert insights into the Los Angeles rental market with Los Angeles Property Management Pros, and learn how to stay ahead in the competitive landscape.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '1',
        title: 'Harnessing Technology for Smarter Property Management',
        excerpt: 'Learn how cutting-edge technology is revolutionizing property management in Los Angeles, making it more efficient and profitable.',
        slug: 'harnessing-technology-for-smarter-property-management',
        tags: ['Technology', 'Innovation', 'Property Management'],
        metaDescription: 'Find out how Los Angeles Property Management Pros utilizes the latest technology to enhance property management services for their clients.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '2',
        title: 'Building Strong Communities: Beyond Property Management',
        excerpt: 'Discover how Los Angeles Property Management Pros contributes to building stronger communities through dedicated management practices.',
        slug: 'building-strong-communities-beyond-property-management',
        tags: ['Community Building', 'Property Management', 'Los Angeles'],
        metaDescription: 'Learn about the role of property management in fostering community spirit and how Los Angeles Property Management Pros is leading the way.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '3',
        title: 'Sustainable Property Management: The Future in LA',
        excerpt: 'Explore the benefits of sustainable property management practices in Los Angeles and how they can positively impact your investment.',
        slug: 'sustainable-property-management-the-future-in-la',
        tags: ['Sustainability', 'Property Management', 'Los Angeles'],
        metaDescription: 'Discover the advantages of sustainable property management practices with Los Angeles Property Management Pros and their impact on your real estate investment.',
        lastModified: new Date('2023-12-03').toISOString()
    }
]

