import React from "react";
import { Box, Heading, Link } from "@chakra-ui/react";

const AboutBelowContent02 = ({ data }) => {
  const { title, description, href, icon: Icon } = data;

  return (
    <Box mb={6}>
      <Link href={href} _hover={{ textDecoration: "unset" }}>
        <div className="about_below_content">
          <Box display={"flex"} gap={"5"} flexDirection={"column"}>
            <Icon color={"#FFA903"} fontSize={"3.5rem"} />
            <Box mb={5}>
              <Heading as={"h3"} mb={3} fontSize={"1rem"}>
                {title}
              </Heading>
              {description}
            </Box>
          </Box>
        </div>
      </Link>
    </Box>
  );
};

export default AboutBelowContent02;
