import React, { lazy } from 'react';
import Slider from '../../Containers/ThemeSliders/Slider';
import { SiteData } from "../../Constants/siteData";
import { SEO } from "../../Components/SEO/SEO";
import { HomeSection } from "../../Constants/homeSection";
import { Box } from "@chakra-ui/react";
import ExperiencesWide from "../../Containers/ExperiencesWide/Experience";
import { FormDataProvider } from "../../Context/FormDataContext";

const Blog = lazy(() => import('../../Containers/Blogs/Blog'));
const FunFact02 = lazy(() => import('../../Containers/Funfacts/FunFact02'));
const Banner = lazy(() => import('../../Containers/Banner'));
const ComplexForm = lazy(() => import('../../Components/FormLayoutWithCards/App'));
const Testimonial01 = lazy(() => import('../../Containers/Testimonials/Testimonial01'));
const Map = lazy(() => import('../../Components/ContactElements/Map'));
const ContactFormSection = lazy(() => import('../../Components/ContactFormSection/ContactFormSection'));

const Home01 = () => {
  return (
    <FormDataProvider>
      <div className="main_wrapper">
        <SEO/>
        <Slider/>

        <ExperiencesWide/>
        <FunFact02/>
        <HomeSection/>
        <ComplexForm/>
        <Testimonial01 pb='pd_btom_80'/>
        <Blog/>
        <Banner
          title={SiteData.contact.closingText()}
          heading='Call Us Anytime'
          phone={SiteData.phoneNumber}
          bannerType='banner banner_bg_color'
        />
        <ContactFormSection data={[{
          id: '1',
          iconClass: 'ion-ios-telephone-outline',
          infoBody01: 'Call us Today!',
          infoBody02: SiteData.phoneNumber,
          link: SiteData.telLink,
          cta: 'Provide some information and get a FREE quote today',
          heading: 'Get a FREE estimate'
        }]}/>
        <Box pt={0} mb={0} minW={'full'}>
          <div className="gmapbox">
            <div id="googleMap" className="map">
              <Map/>
            </div>
          </div>
        </Box>
      </div>
    </FormDataProvider>
  );
};

export default Home01;