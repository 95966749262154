import React from "react";
import {createBrowserRouter} from 'react-router-dom';
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import ContactPage from '../Pages/ContactPage';
import Home01 from '../Pages/Homes/Home01';
import Services01 from '../Pages/Services/Services01';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Blog from "../Pages/Blog";
import Careers from "../Pages/Careers";
import {BlogPost} from "../Layouts/BlogPost";
import AcidWashing from "../Pages/Services/AcidWashing";
import EquipmentRepair from "../Pages/Services/EquipmentRepair";
import EmergencyPoolServices from "../Pages/Services/EmergencyPoolServices";
import PoolRepair from "../Pages/Services/PoolRepair";
import PoolHealthConsulting from "../Pages/Services/PoolHealthConsulting";
import TileCleaning from "../Pages/Services/TileCleaning";
import PoolCleaning from "../Pages/Services/PoolCleaning";
import LosAngeles from "../Pages/ServiceAreas/los-angeles";
import PropertyManagementBeverlyHills from "../Pages/ServiceAreas/property-management-beverly-hills";
import PropertyManagementBurbank from "../Pages/ServiceAreas/property-management-burbank";
import HollywoodPropertyManagement from "../Pages/ServiceAreas/hollywood-property-management";
import KoreatownPropertyManagement from "../Pages/ServiceAreas/koreatown-property-management";
import LosFelizPropertyManagement from "../Pages/ServiceAreas/los-feliz-property-management";
import SantaMonicaPropertyManagement from "../Pages/ServiceAreas/santa-monica-property-management";
import SanFernandoValleyPropertyManagement from "../Pages/ServiceAreas/san-fernando-valley-property-management";
import Silverlake from "../Pages/ServiceAreas/silverlake";
import StudioCityPropertyManagement from "../Pages/ServiceAreas/studio-city-property-management";
import VenicePropertyManagement from "../Pages/ServiceAreas/venice-property-management";
import WestHollywoodPropertyManagement from "../Pages/ServiceAreas/west-hollywood-property-management";
import WestLosAngelesPropertyManagement from "../Pages/ServiceAreas/west-los-angeles-property-management";
import WestwoodPropertyManagement from "../Pages/ServiceAreas/westwood-property-management";
import CulverCityPropertyManagement from "../Pages/ServiceAreas/culver-city-property-management";
import EchoParkPropertyManagement from "../Pages/ServiceAreas/echo-park-property-management";
import Encinco from "../Pages/ServiceAreas/encinco";
import GlendalePropertyManagement from "../Pages/ServiceAreas/glendale-property-management";
import MidCityPropertyManagement from "../Pages/ServiceAreas/mid-city-property-management";
import NorthHollywoodPropertyManagement from "../Pages/ServiceAreas/north-hollywood-property-management";
import Pasadena from "../Pages/ServiceAreas/pasadena";
import ShermanOaks from "../Pages/ServiceAreas/sherman-oaks";
import Tarzana from "../Pages/ServiceAreas/tarzana";
import WoodlandHills from "../Pages/ServiceAreas/woodland-hills";
import FAQ from "../Pages/FAQ";
import PoolMaintenance from "../Pages/Services/PoolMaintenance";

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                path: '/acid-washing',
                element: <AcidWashing/>
            },
            {
                path: '/equipment-repair',
                element: <EquipmentRepair/>
            },
            {
                path: '/emergency-pool-services-los-angeles',
                element: <EmergencyPoolServices/>
            },
            {
                path: '/pool-repair-los-angeles',
                element: <PoolRepair/>
            },
            {
                path: '/pool-health-consulting-los-angeles',
                element: <PoolHealthConsulting/>
            },
            {
                path: '/pool-tile-cleaning-los-angeles',
                element: <TileCleaning/>
            },
            {
                path: '/regular-pool-maintenance-los-angeles',
                element: <PoolMaintenance/>
            },
            {
                path: '/pool-cleaning-los-angeles',
                element: <PoolCleaning/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/careers',
                element: <Careers/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/blog/:id',
                element: <BlogPost/>
            },
            {
                path: '/los-angeles-pool-service',
                element: <LosAngeles/>
            },
            {
                path: '/property-management-beverly-hills',
                element: <PropertyManagementBeverlyHills/>
            },
            {
                path: '/property-management-burbank',
                element: <PropertyManagementBurbank/>
            },
            {
                path: '/hollywood-pool-service',
                element: <HollywoodPropertyManagement/>
            },
            {
                path: '/koreatown-pool-service',
                element: <KoreatownPropertyManagement/>
            },
            {
                path: '/los-feliz-pool-service',
                element: <LosFelizPropertyManagement/>
            },
            {
                path: '/santa-monica-pool-service',
                element: <SantaMonicaPropertyManagement/>
            },
            {
                path: '/san-fernando-valley-pool-service',
                element: <SanFernandoValleyPropertyManagement/>
            },
            {
                path: '/silver-lake-pool-service',
                element: <Silverlake/>
            },
            {
                path: '/studio-city-pool-service',
                element: <StudioCityPropertyManagement/>
            },
            {
                path: '/venice-pool-service',
                element: <VenicePropertyManagement/>
            },
            {
                path: '/west-hollywood-pool-service',
                element: <WestHollywoodPropertyManagement/>
            },
            {
                path: '/west-los-angeles-pool-service',
                element: <WestLosAngelesPropertyManagement/>
            },
            {
                path: '/westwood-pool-service',
                element: <WestwoodPropertyManagement/>
            },
            {
                path: '/culver-city-pool-service',
                element: <CulverCityPropertyManagement/>
            },
            {
                path: '/echo-park-pool-service',
                element: <EchoParkPropertyManagement/>
            },
            {
                path: '/encino-pool-service',
                element: <Encinco/>
            },
            {
                path: '/glendale-pool-service',
                element: <GlendalePropertyManagement/>
            },
            {
                path: '/mid-city-pool-service',
                element: <MidCityPropertyManagement/>
            },
            {
                path: '/north-hollywood-pool-service',
                element: <NorthHollywoodPropertyManagement/>
            },
            {
                path: '/pasadena-pool-service',
                element: <Pasadena/>
            },
            {
                path: '/sherman-oaks-pool-service',
                element: <ShermanOaks/>
            },
            {
                path: '/tarzana-pool-service',
                element: <Tarzana/>
            },
            {
                path: '/woodland-hills-pool-service',
                element: <WoodlandHills/>
            },
            {
                path: '/faq',
                element: <FAQ/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            },
        ]
    }
])

