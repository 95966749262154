import $ from 'jquery';
import React, {useEffect} from 'react';
import PageHeader from '../Components/common/PageHeader';
import Banner from '../Containers/Banner';
import {SiteData} from "../Constants/siteData";
import {Box} from "@chakra-ui/react";

const faqs = [
    {
        "q": "What types of pools do you service?",
        "a": "We specialize in servicing a variety of pool types, including residential pools like in-ground and above-ground pools, as well as commercial pools in hotels, gyms, and public facilities in Los Angeles and surrounding areas."
    },
    {
        "q": "What does your pool maintenance service include?",
        "a": "Our pool maintenance service is comprehensive and includes regular cleaning, chemical balance checks, filter cleaning, and equipment checks to ensure your pool is always in perfect condition."
    },
    {
        "q": "How often should I have my pool serviced?",
        "a": "We recommend regular pool servicing, typically on a weekly or bi-weekly basis, to maintain optimal water quality and equipment functionality. However, service frequency can vary based on pool usage and type."
    },
    {
        "q": "Do you offer repair services for pool equipment?",
        "a": "Yes, we provide repair services for all types of pool equipment, including pumps, heaters, filters, and lighting systems. Our skilled technicians can diagnose and fix issues promptly."
    },
    {
        "q": "Are your pool technicians certified?",
        "a": "Absolutely, our pool technicians are certified and have extensive training and experience in pool maintenance and repair, ensuring high-quality and reliable service."
    },
    {
        "q": "Do you provide pool opening and closing services?",
        "a": "Yes, we offer pool opening services in the spring and pool closing services in the fall to prepare your pool for the changing seasons."
    },
    {
        "q": "What is your pricing for pool services?",
        "a": "Our pricing varies based on the type and size of the pool, as well as the services required. We offer competitive rates and can provide a detailed quote based on your specific pool service needs."
    },
    {
        "q": "How do you handle emergency pool repairs?",
        "a": "We offer emergency repair services and can respond quickly to urgent issues to prevent further damage and ensure your pool remains usable."
    },
    {
        "q": "What sets your pool service apart from others in Los Angeles?",
        "a": "Our dedication to customer satisfaction, expert knowledge of pool systems, and prompt, reliable service set us apart. We are committed to maintaining your pool’s health and appearance."
    },
    {
        "q": "How can I schedule a service with Best Pool Service LA?",
        "a": "Scheduling a service is easy! Just contact us through our website or call us directly. We’ll discuss your pool service needs and set up a convenient time for our visit."
    }
]

const FAQ = () => {
    useEffect(() => {
        // accordion
        $(".accordion").on("click", ".accordion_tab", function () {
            $(this).next().slideDown();
            $(".accordion_info").not($(this).next()).slideUp();
        });

        $(".accordion").on("click", ".item", function () {
            $(this).addClass("active").siblings().removeClass("active");
        });

    }, []);


    return (
        <>
            <PageHeader activeClass01='active' activeClass02='d-none' routeName01='FAQ'
                        header='Frequently Asked Questions' subtitle={'Let\'s give you some answers'}/>

            <div className="main_wrapper">
                <div className="faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12">
                                <div className="images-faq">
                                    <img src="images/man.webp" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <Box py={20}>
                                    <div className="accordion">
                                        {faqs.map(({q, a}) => {
                                            return (
                                                <div className="item">
                                                    <div className="accordion_tab">
                                                        <h2 className="accordion_title">{q}</h2>
                                                        <span className="accordion_tab_icon">
                                                <i className="open_icon ion-ios-arrow-down"></i>
                                                <i className="close_icon ion-ios-arrow-up"></i>
                                            </span>
                                                    </div>
                                                    <div className="accordion_info">
                                                        {a}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>

                <Banner
                    title={SiteData.contact.closingText}
                    heading='Call Us Anytime'
                    phone={SiteData.phoneNumber}
                    bannerType='banner banner_bg_color'
                />

            </div>
        </>
    );
};

export default FAQ;